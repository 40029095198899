/** Angular Modules **/
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeCa from '@angular/common/locales/ca';
import localeEn from '@angular/common/locales/en';
import { DatePipe, registerLocaleData } from '@angular/common';

/** Ionic Modules **/
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

/** Third Party Modules **/
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
import { Media } from '@awesome-cordova-plugins/media/ngx';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';

/** Components and app modules **/
import { LocalizedDatePipe } from '@shared/pipes/localized-date.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from 'src/app/interceptors/token.interceptor';
import { environment } from '@environments/environment';



// Note we need a separate function as it's required
// by the AOT compiler.
export const playerFactory = ()  => player;

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// export const httpLoaderFactory =  (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

registerLocaleData(localeEs, 'es');
registerLocaleData(localeCa, 'ca');
registerLocaleData(localeEn, 'en');

Sentry.init(
    {
      dsn: 'https://f5d7a2d2780f4e2ab672da864c97059e@o854552.ingest.sentry.io/6602614',
      // To set your release and dist versions
      release: 'dytective-cap@' + environment.appVersion,
      dist: '1',
      ignoreErrors: [
          'ScreenOrientation',
          'isAlphaPremultiplied',
          'ApplicationNotResponding',
          'ChunkLoadError'
      ]
    },
    // Forward the init method to the sibling Framework.
    SentryAngular.init
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    AppRoutingModule],
  providers: [
    Media,
    LocalizedDatePipe,
    provideLottieOptions({
      player: () => player,
    }),
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: 'APP_NAME',
      useValue: environment.appName
    },
    {
      provide: 'APP_VERSION',
      useValue: environment.appVersion
    },
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
