/** Angular Modules **/
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

/** Guards  **/
import { UserGuard } from '@guards/user.guard';

const routes: Routes = [
  { path: '', redirectTo: 'splash', pathMatch: 'full' },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'usertype',
    loadChildren: () => import('./pages/usertype/usertype.module').then( m => m.UsertypePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'admin-tabs',
    loadChildren: () => import('./pages/admin-tabs/admin-tabs.module').then( m => m.AdminTabsPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'dytectiveu',
    loadChildren: () => import('./pages/dytectiveu/dytectiveu.module').then( m => m.DytectiveuPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'prephaser/:direction',
    loadChildren: () => import('./pages/prephaser/prephaser.module').then( m => m.PrephaserPageModule)
  },
  {
    path: 'pretest',
    loadChildren: () => import('./pages/pretest/pretest.module').then( m => m.PretestPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'dytectivetest',
    loadChildren: () => import('./pages/dytectivetest/dytectivetest.module').then( m => m.DytectivetestPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'alumni-home',
    loadChildren: () => import('./pages/alumni-home/alumni-home.module').then( m => m.AlumniHomePageModule),
    canActivate: [UserGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
