export class Test {
    id: string;
    hasResult = false;
    isMoreThan3MonthsAgo =  false;
    startedAt: string;
    endedAt: string;
    normalizedResult: number;
    isDyslexic: boolean;
    testDoneCorrectly: boolean;

    populate(data){
        this.id = data.id;
        this.hasResult = data.hasResult;
        this.isMoreThan3MonthsAgo = data.isMoreThan3MonthsAgo;
        this.startedAt = data.started_at;
        this.endedAt = data.hasOwnProperty('ended_at') ? data.ended_at : '';
        this.normalizedResult = data.normalizedResult;
        this.isDyslexic = data.isDyslexic;
        this.testDoneCorrectly = data.testDoneCorrectly;
    };
}
