export const environment = {
  appName: 'Dytective',
  appVersion: '2.3.10',
  production: true,
  apiURL: 'https://www.changedyslexia.org/api',
  alumniLoginUrl: 'https://www.changedyslexia.org/signin/dyu',
  adminLoginUrl: 'https://www.changedyslexia.org/signin',
  adminDashboardUrl: 'https://www.changedyslexia.org/dashboard'
};

export const firebaseConfig = {
  apiKey: 'AIzaSyC2n_FGfheuCVhJG4RLncKVWIO8kr-POG4',
  authDomain: 'dytective-e6cb0.firebaseapp.com',
  databaseURL: 'https://dytective-e6cb0.firebaseio.com',
  projectId: 'dytective-e6cb0',
  storageBucket: 'dytective-e6cb0.appspot.com',
  messagingSenderId: '613269762762',
  appId: '1:613269762762:web:97f77367774a8e03b9ba33',
  measurementId: 'G-W2K9YL6617'
};

export const dynamicLinksConfig = {
  domainUriPrefix: 'https://dytective.page.link',
  linkPrefix: 'https://dytective.com'
};

export const market = {
  iosMarketLink: 'https://itunes.apple.com/us/app/dytective/id1384568741?l=es&ls=1&mt=8',
  androidMarketLink: 'market://details?id=org.changedyslexia.newdytective',
};

export const iosConfig = {
  appStoreId: '1384568741',
  bundleId: 'org.changedyslexia.newdytective',
  // iosFallbackLink: 'https://itunes.apple.com/us/app/dytective/id1384568741?l=es&ls=1&mt=8'
};

export const androidConfig = {
  // androidMarketLink: 'market://details?id=org.changedyslexia.newdytective',
  // androidFallbackLink: 'https://play.google.com/store/apps/details?id=org.changedyslexia.newdytective',
  packageName: 'org.changedyslexia.newdytective',
  minimumVersion: 75
};

export const purchasesConfig = {
  // publicSdkKey: 'DNheuRShKWTTcGPiKEFyfmOtwgGMQXAB'
  appleKey: 'appl_WPtwUbhRqVKtYsRWnWsddgKubOd',
  androidKey: 'goog_QUrnnMktFfmHksPiVeUUYFnrvJC'
};

export const sentryConfig = {
  dsn: 'https://f5d7a2d2780f4e2ab672da864c97059e@o854552.ingest.sentry.io/6602614'
};
