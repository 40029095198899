import {Test} from './test';

export class Alumni {
    id: string;
    username: string;
    gender: string;
    birthdate: string;
    password: string;
    isPasswordSet = false;
    code: string;
    age: number;
    bilingual: string;
    motherLanguage: string;
    languageSubject: string;
    knowHowToRead: string;
    avatar: string;
    activeAvatar = '0001';
    coins = 0;
    music = true;
    mapPosition1 = 0;
    mapPosition2 = 0;
    mapPosition3 = 0;
    map1Finished = false;
    map2Finished = false;
    map3Finished = false;
    isTestAvailable = true;
    isInSubscription = false;
    hasTestResult = false;
    isDyslexic = false;
    hasActiveSuscription = false;
    testDate: string;
    storeItems = [];
    tests = [];
    testsToShow = [];
    hasToDoTest = false;
    numChallenges: number;
    lastChallengeAt: string;
    credits = 0;
    groups: string;
    timeFactor = 1;
    locale: any;
    program = '';

    populate(data) {
        this.id = data.id;
        this.username = data.username;
        this.birthdate = data.birthdate;
        this.age = data.age;
        this.gender = data.gender;
        this.motherLanguage = data.motherLanguage;
        this.languageSubject = data.languageSubject;
        this.bilingual = data.bilingual;
        this.knowHowToRead = data.knowHowtoRead;
        this.avatar = data.avatar;
        this.activeAvatar = data.activeAvatar;
        this.coins = data.gameStatus !== undefined ? data.gameStatus.coins : 0 ;
        this.isTestAvailable = data.isTestAvailable;
        this.hasTestResult = data.hasTestResult;
        this.hasActiveSuscription = data.hasActiveSuscription;
        this.isInSubscription = data.isInSubscription;
        this.numChallenges = data.gameStatus !== undefined ? data.gameStatus.num_challenges : 0;
        this.lastChallengeAt = data.gameStatus !== undefined ? data.gameStatus.last_challenge_at : '';
        this.credits = data.credits;
        this.groups = data.groups;
        this.locale = data.locale !== undefined ? data.locale : 'es';
        this.program = data.program !== undefined ? data.program : '';


        if (data.hasTestResult) {
            this.isDyslexic = data.isDyslexic;
        }
        this.hasToDoTest = data.hasToDoTest;

        if (data.password !== '') {
            this.isPasswordSet = true;
        }
        this.code = data.code ? data.code : '';
        this.music = data.gameStatus !== undefined ? data.gameStatus.music : true ;
        this.mapPosition1 = data.gameStatus !== undefined ? data.gameStatus.map_position1 : 0 ;
        this.mapPosition2 = data.gameStatus !== undefined ? data.gameStatus.map_position2 : 0 ;
        this.mapPosition3 = data.gameStatus !== undefined ? data.gameStatus.map_position3 : 0 ;
        this.map1Finished = data.gameStatus !== undefined ? data.gameStatus.map1_finished : false ;
        this.map2Finished = data.gameStatus !== undefined ? data.gameStatus.map2_finished : false ;
        this.map3Finished = data.gameStatus !== undefined ? data.gameStatus.map3_finished : false ;
        this.timeFactor = data.gameStatus !== undefined ? data.gameStatus.time_factor : 1 ;

        this.storeItems = data.gameStatus !== undefined ? data.gameStatus.store_items : [];
        if (data.tests) {
            data.tests.forEach((currentValue) => {
                const test = new Test();
                test.populate(currentValue);
                this.tests.push(test);
                if (test.hasResult) {
                    this.testsToShow.push(test);
                }
            }, this);

            this.tests.sort((a: Test,b: Test) => new Date(b.endedAt).getTime() - new Date(a.endedAt).getTime());
            this.testsToShow.sort((a: Test,b: Test) => new Date(b.endedAt).getTime() - new Date(a.endedAt).getTime());
            if (this.testsToShow.length) {
                this.testDate = this.testsToShow.length > 0 ? this.testsToShow[0].endedAt : '';
            }
        }else{
            this.tests = [];
        }
    }

    getLastTest(){
        if (this.tests.length === 0) {
            return null;
        }
        return this.tests.reduce((r: Test, a: Test) => r.startedAt > a.startedAt ? r : a);
    }
}
