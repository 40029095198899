/** Angular Modules **/
import { Injectable } from '@angular/core';


/** Third party Modules **/
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links';


/** config **/
import {androidConfig, dynamicLinksConfig, iosConfig} from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DynamicLinksService {

  private currentDeepLink: string = null;

  constructor() { }

  handleDeepLinkWithoutAppLaunched() {
    FirebaseDynamicLinks.addListener('deepLinkOpen', (data) => {
      console.log('DEEP LINKING', data);
      if (data) {
        if (data.url) {
         const url = this.generateInternalUrl(data.url);
         this.setCurrentDeepLink(url);
        }
      }
      return null;
    });
  }

  async generateDynamicLinkToRecoverPassword(email: string): Promise<string> {
    return FirebaseDynamicLinks.createDynamicShortLink({
      domainUriPrefix: dynamicLinksConfig.domainUriPrefix,
      uri: `${dynamicLinksConfig.linkPrefix}/login/new-password?email=${email}`,
      androidParameters: androidConfig,
      iosParameters: iosConfig,
      navigationInfo: {
        forcedRedirectEnabled: true
      }
    }).then(link => {
      console.log('generateDynamicLinkToRecoverPassword', link.value);
      return link.value;
    }).catch(reason => {
      console.log('Error', reason);
      return null;
    });
  }

  generateInternalUrl(url: string) {
    console.log('generateInternalUrl');
    if (url.indexOf('https://dytective.com/login/new-password') === 0) {
      const email = url.substring(url.indexOf('=') + 1);
      console.log(email);
      return '/login/new-password/' + email;
    }
    return null;
  }

  setCurrentDeepLink(url) {
    this.currentDeepLink = url;
  }

  hasCurrentDeepLink() {
    return this.currentDeepLink !== null;
  }

  getCurrentDeepLink() {
    return this.currentDeepLink;
  }
}
