/** Angular Modules **/
import { Injectable } from '@angular/core';

/** Capacitor **/
import { CustomerInfo, LOG_LEVEL, PRODUCT_CATEGORY, PRODUCT_TYPE, Purchases, PurchasesOffering, PurchasesStoreProduct } from '@revenuecat/purchases-capacitor'

/** Services **/
/** Models adn config **/
import { environment, purchasesConfig } from '@environments/environment';
import { User } from 'src/app/model/user';
import { isPlatform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PurchasesService {

  private customerInfo: CustomerInfo;
  private offering: PurchasesOffering;
  private product: PurchasesStoreProduct;
  isInitialized = false;

  // constructor(){ }

  async init() {
    if (isPlatform('capacitor') && !this.isInitialized) {
      try {
        await Purchases.setLogLevel({ level: environment.production ? LOG_LEVEL.INFO : LOG_LEVEL.DEBUG }) // Enable to get debug logs in dev mode
        if (isPlatform('ios')) {
          await Purchases.configure({ apiKey: purchasesConfig.appleKey})
        } else if (isPlatform('android')) {
          await Purchases.configure({ apiKey: purchasesConfig.androidKey})
        }

        const { current } = await Purchases.getOfferings();
        this.offering = current;
        this.product = this.offering.sixMonth.product;
        this.isInitialized = true;
        console.log('APPINIT RevenueCat initialized', this.offering);
      } catch (e) {
        console.log('APPINIT RevenueCat initialization failed', e);
      }
    } else {
      this.product = {
        currencyCode:"EUR",
        description:"Accede a todos los servicios de Maternity+",
        discounts:[],
        priceString:"99,99€",
        title:"",
        identifier:"",
        price:99.99,
        subscriptionPeriod: 'P6M',
        introPrice: null,
        defaultOption: null,
        productType: PRODUCT_TYPE.PREPAID_SUBSCRIPTION,
        productCategory: PRODUCT_CATEGORY.NON_SUBSCRIPTION,
        presentedOfferingContext: null,
        presentedOfferingIdentifier: null,
        subscriptionOptions: null
      };
      console.log('APPINIT RevenueCat not initialized. Browser platform');
    }
  }

  async identifyUser(user: User) {
    if (isPlatform('capacitor')) {
      await Purchases.logIn({appUserID: user.id});
      await  Purchases.setAttributes({
        $displayName: user.firstname + ' ' + user.lastname,
        $email: user.email
      });
      console.log(`RevenueCat identify user with uid ${user.id} and email ${user.email}`);
    }
  }

  async logoutUser() {
    if (isPlatform('capacitor')) {
      console.log('RevenueCat logout user');
      return Purchases.logOut();
    }
  }

  canMakePayments() {
    return Purchases.canMakePayments();
  }

  getSubscriptionFamily() {
    return this.product;
  }

  async purchaseSubscription() {
      // console.log('purchase', p)
      const { customerInfo } = await Purchases.purchasePackage({
        aPackage: this.offering.sixMonth
      })
      console.log('listenBuy', customerInfo)
      this.customerInfo = customerInfo;
      return customerInfo;
  }
}
