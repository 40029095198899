/** Angular Modules **/
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';

/** Third party modules **/
import { from, Observable } from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(Preferences.get({key: 'authToken'})).pipe(
          mergeMap(authToken => {
              if (authToken.value) {
                  request = request.clone({
                      setHeaders: {
                          Authorization: `Bearer ${authToken.value}`
                      }
                  });
              }
              return next.handle(request);
          })
        );
    }
}
