/** Angular Modules **/
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

/** Capacitor Modules **/
import { Preferences } from '@capacitor/preferences';

/** Third party modules **/
import jwt_decode from 'jwt-decode';
import {Subject} from 'rxjs';
import * as Sentry from '@sentry/capacitor';

/** Firebase **/
import { UserCredential } from 'firebase/auth';

/** Services **/
import {ApiService} from './api.service';
import {ToastService} from './toast.service';
import {PurchasesService} from './purchases.service';

/** Models **/
import {User, UserOauth} from '@models/user';
import {Alumni} from '@models/alumni';
import {PackageMetrics} from '@models/metrics';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    user: User;
    alumni: Alumni;
    isWebSession = false;
    typeWebSession = 'alumni';
    isAlumniAccount = false;
    userOAuth: UserOauth = null;

    alumnisUpdated: Subject<any> = new Subject();

    constructor(private apiService: ApiService,
                private toastService: ToastService,
                private purchaseService: PurchasesService,
                private router: Router) {
    }

    setOauthFields(firebaseUser: UserCredential, provider: string) {
      this.userOAuth = new UserOauth(firebaseUser, provider);
      console.log('oauthUser', this.userOAuth);
    }

    isOauthUser() {
      return this.userOAuth !== null;
    }

    setUserWithToken(token) {
        const tokenDecoded = jwt_decode(token);
        console.log('tokenDecoded', tokenDecoded);
        this.user = new User();
        this.user.id = tokenDecoded['uuid'];
        this.user.email = tokenDecoded['username'];
        this.user.token = token;
        // Identify in Sentry
        Sentry.setUser({
            email: this.user.email,
            role: this.user.role,
            id: this.user.id
        });
        // Identify User in RevenueCat
        this.purchaseService.identifyUser(this.user);
        console.log('user set up by token', this.user);
    }

    public existUser(email): Promise<any> {
        return this.apiService.userExist(email)
          .then(response => response.exist)
          .catch(async error => {
              await this.manageAPIError(error);
              return null;
          });
    }

  public isCMValidUser(email): Promise<any> {
    return this.apiService.isCMValidUser(email)
      .then(response => response.valid)
      .catch(async error => {
        await this.manageAPIError(error);
        return null;
      });
  }

    public existUserWithProviders(email): Promise<any> {
        return this.apiService.userExistWithProviders(email)
          .then(response => response)
          .catch(async error => {
              await this.manageAPIError(error);
              return null;
          });
    }

    getUser(alumniId: string = null): Promise<any> {
        console.log('getuser');
        return this.apiService.getUser((this.user.id))
          .then(async response => {
              console.log('user data from server', response);
              await this.populateUser(response);
              if (alumniId) {
                console.log('getUser Alumni Id', alumniId);
                this.isAlumniAccount = true;
                this.alumni = await this.getAlumni(alumniId);
                await this.setActiveAlumni(this.alumni);
                await Preferences.set({ key: 'userActiveAlumni', value: this.alumni.id });
              } else {
                await Preferences.set({ key: 'isAlumniAccount', value: 'false' });
                this.isAlumniAccount = false;
              }
          })
          .catch(async error => {
              await this.manageAPIError(error);
          });
    }

    startTrial(): Promise<any> {
        return this.apiService.startTrial(this.user.id)
            .then(response => {
                console.log('User Trial started', response.status === 'created');
            })
            .catch(async error => {
                await this.manageAPIError(error);
            });
    }

    startSubscription(platform: string): Promise<any> {
        return this.apiService.setSubscription(this.user.id, platform)
            .then(response => {
                console.log('User Subscription started', response.status === 'created');
            })
            .catch(async error => {
                await this.manageAPIError(error);
            });
    }

    async setAlumniAccount(value: boolean) {
      await Preferences.set({key: 'isAlumniAccount' , value: JSON.stringify(value)});
      this.isAlumniAccount = value;
    }

    getAlumni(alumniId: string): Promise<any> {
      return this.apiService.getAlumni(this.user.id, alumniId)
        .then(async response => {
            const alumni = new Alumni();
            alumni.populate(response);
            await this.setActiveAlumni(alumni);
            return alumni;
        })
        .catch(async error => {
            await this.manageAPIError(error);
        });
    }

    rememberPassword(email, dynamicLink): Promise<any> {
        return this.apiService.rememberPassword(email, dynamicLink)
          .then(async response => {
              console.log('response', response);
              if (response.exist) {
                  return true;
              } else {
                  await this.toastService.showSignInError('user-not-found');
                  return false;
              }
          })
          .catch(async error => {
              await this.manageAPIError(error);
              return null;
          });
    }

    setNewPassword(email, password): Promise<any> {
        return this.apiService.setNewUserPassword(email, password)
          .then(async response => {
              if (!response.error) {
                  return true;
              } else {
                  await this.toastService.showSignInError('user-not-found');
                  return false;
              }
          })
          .catch(async error => {
              await this.manageAPIError(error);
              return null;
          });
    }

    setNewUser(newUser: object) {
      return this.apiService.setNewUser(newUser)
        .then(async () => true)
        .catch(async error => {
          await this.manageAPIError(error);
          return false;
        });
    }

    updateUser(uid: string, newUser: object) {
        return this.apiService.updateUser(uid, newUser)
            .then(async () => {
                await this.getUser();
                return true;
            })
            .catch(async error => {
                await this.manageAPIError(error);
                return false;
            });

    }

    deleteUser() {
        return this.apiService.deleteUser()
            .then(async () => true )
            .catch(async error => {
                await this.manageAPIError(error);
                return false;
            });
    }

    setUserPreferences(preferences: object) {
      return this.apiService.postUserPreferences(preferences)
        .then(async response => response)
        .catch(async error => {
            await this.manageAPIError(error);
            return false;
        });
    }

    async populateUser(data) {
        this.user.populate(data);
    }

  public existAlumni(username): Promise<any> {
    return this.apiService.alumniExist(username)
      .then(response => response.exist)
      .catch(async error => {
        await this.manageAPIError(error);
        return null;
      });
  }

  setNewAlumni(newAlumni: object) {
    return this.apiService.setNewAlumni(newAlumni)
      .then(async () => {
        this.alumnisUpdated.next(null);
        return true;
      })
      .catch(async error => {
        await this.manageAPIError(error);
        return false;
      });
  }

  updateAlumni(aid: string, newAlumni: object) {
    return this.apiService.updateAlumni(aid, newAlumni)
      .then(async () => {
        this.alumnisUpdated.next(null);
        return true;
      })
      .catch(async error => {
        await this.manageAPIError(error);
        return false;
      });

  }

  async deleteAlumni(aid: string) {
    await this.removeActiveAlumni();
    return this.apiService.deleteAlumni(aid)
      .then(async () => {
        this.alumnisUpdated.next(null);
        return true;
      })
      .catch(async error => {
        await this.manageAPIError(error);
        return false;
      });

  }

  getActiveAlumni(): Alumni | null {
      console.log('getActiveAlumni 1', this.alumni);
      return this.alumni;
  }

  async setActiveAlumni(alumni: Alumni) {
    this.alumni = alumni;
    await Preferences.set({key: 'userActiveAlumni', value: this.alumni.id});
    console.log('setActiveAlumni', this.alumni);
  }

  async removeActiveAlumni() {
    this.alumni = null;
    await Preferences.remove({key: 'userActiveAlumni'});
  }

    getAlumnis(page: number, search: string) {
      return this.apiService.getUserAlumnis(this.user.id, page, search)
        .then(response => {
          const alumnis: Alumni[] = [];
          response.forEach(item => {
              // console.log(item);
            const alumni = new Alumni();
            alumni.populate(item);
            alumnis.push(alumni);
          });
          return alumnis;
          // return response;
        })
        .catch(async error => {
          await this.manageAPIError(error);
          return null;
        });
    }

    getAlumniReport(alumni: Alumni) {
      return this.apiService.alumniReport(alumni.id)
        .then(response => response)
        .catch(async error => {
          await this.manageAPIError(error);
          return null;
        });
    }

    postAlumniAssignCredits(alumni: Alumni, numCredits: number) {
        return this.apiService.postAlumniAssignCredits(alumni, numCredits)
            .then(async () => {
                this.user.numCredits -= numCredits;
                this.alumnisUpdated.next(null);
                return true;
            })
            .catch(async error => {
                await this.manageAPIError(error);
                return false;
            });
    }

    postAddAlumniToSubscription(alumni: Alumni) {
        return this.apiService.postAddAlumniToSubscription(alumni.id)
            .then(async response => {
                console.log('postAddAlumniToSubscription', response);
                this.alumnisUpdated.next(null);
                return true;
            })
            .catch(async error => {
                await this.manageAPIError(error);
                return false;
            });
    }

    postSupportTicket(data: any) {
        return this.apiService.postSupport(data)
            .then(async () => true)
            .catch(async error => {
                await this.manageAPIError(error);
                return false;
            });
    }

    /**+
     * Mute or unmute music in Dyu
     */
    async toggleSound(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.alumni.music = !this.alumni.music;
            this.apiService.postAlumniPreferences(this.alumni, {music: this.alumni.music}).then(
                () => {
                    console.log('alumni preferences updated');
                    resolve(null);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    /**+
     * Mute or unmute music in Dyu
     */
    async setAlumniPreferences(preferences: object): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.postAlumniPreferences(this.alumni, preferences).then(
                () => {
                    console.log('alumni preferences updated');
                    resolve(null);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    /**+
     * Set a new item bought in store
     */
    async setNewItemStore(itemStore: object): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.setNewStoreItem(this.alumni, itemStore).then(
                () => {
                    console.log('alumni new item saved');
                    resolve(null);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    async getChallenge(): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscription = this.apiService.getChallenge(this.alumni.id).subscribe(
                response => {
                    console.log('challenge retrieved', response);
                    subscription.unsubscribe();
                    resolve(response);
                },
                error => {
                    subscription.unsubscribe();
                    reject(error);
                }
            );
        });
    }

    async getTest(): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscription = this.apiService.getTest(this.alumni.id).subscribe(
                response => {
                    console.log('test retrieved', response);
                    subscription.unsubscribe();
                    resolve(response);
                },
                error => {
                    subscription.unsubscribe();
                    reject(error);
                }
            );
        });
    }

    async sendTest(metrics): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.postTest(this.alumni.id, metrics).then(
                response => {
                    console.log('alumni test sent');
                    resolve(response);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    /**+
     * Send Exercise error data
     */
    async sendError(errorData, packageData, exerciseData): Promise<any> {
        return new Promise((resolve, reject) => {
            this.alumni.music = !this.alumni.music;
            this.apiService.sendError(errorData, this.alumni.username, packageData, exerciseData).then(
                () => {
                    console.log('Exercise Error data sended');
                    resolve(null);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    sendChallengeResults(packages: PackageMetrics[], coinsEarned: number, locale = 'es') {
        const data = {
            date      : (new Date()).toJSON(),
            locale,
            gameCoins : coinsEarned,
            packages,
            mapPosition1  : this.alumni.mapPosition1,
            mapPosition2  : this.alumni.mapPosition2,
            mapPosition3  : this.alumni.mapPosition3
        };
        return new Promise((resolve, reject) => {
            this.apiService.postChallenge(this.alumni, data).then(
                response => {
                    console.log('alumni challenge sent');
                    this.alumnisUpdated.next(null);
                    resolve(response);
                },
                error => {
                    reject(error);
                }
            );
        });
    }

    async manageAPIError(error) {
        console.log(`error in API Service :: Error Code: ${error.status}\nMessage: ${error.message}`);
        if (error.status === 401) {
            await this.router.navigateByUrl('/login');
        } else {
            await this.toastService.showGenericConnectionError();
        }
    }
}
