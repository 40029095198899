/** Angular Modules **/
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

/** Capacitor Modules **/
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device';

/** Third party modules **/
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { PlatformService } from '@services/platform.service';
import {TranslateService} from '@ngx-translate/core';

/** Config **/
import { environment } from '@environments/environment';


/** Services **/

@Injectable({
    providedIn: 'root'
})
export class UserGuard  {

    constructor(private authService: AuthService,
                private userService: UserService,
                private router: Router,
                private translate: TranslateService,
                private platformService: PlatformService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkUser();
    }

    async checkUser() {
        console.log(1);
        if (!this.userService.user) {
            console.log(2);
            const token = await Preferences.get({key: 'authToken'});
            console.log('guard token', token);
            const alumniId = await Preferences.get({key: 'userActiveAlumni'});
            const isAlumniAccount = await Preferences.get({key: 'isAlumniAccount'});
            if (token) {
              if (token.value && alumniId.value) {
                await this.authService.getUserFromToken(token.value, alumniId.value);
                await this.userService.setAlumniAccount(isAlumniAccount.value === 'true');
              } else {
                await this.authService.getUserFromToken();
                await this.userService.setAlumniAccount(isAlumniAccount.value === 'true');
              }

              /** TODO cat **/
              // if (this.userService.user.preferences.locale === '') {
              //   this.userService.user.preferences.locale = await this.getBrowserLocale();
              //   this.userService.setUserPreferences(this.userService.user.preferences);
              // } else {
              //   this.translate.use(this.userService.user.preferences.locale);
              // }
            }else {
              console.log('UserGuard false');
              if (this.platformService.isCapacitor()) {
                await this.router.navigate(['/usertype']);
              } else {
                window.location.href = environment.alumniLoginUrl;
              }
              return false;
            }

            // if (token.value && alumniId.value) {
            //     await this.authService.getUserFromToken(token.value, alumniId.value);
            //     await this.userService.setAlumniAccount(isAlumniAccount.value === 'true');
            //     if (this.userService.user.preferences.locale === '') {
            //       this.userService.user.preferences.locale = await this.getBrowserLocale();
            //       await this.userService.setUserPreferences(this.userService.user.preferences);
            //     } else {
            //       this.translate.use(this.userService.user.preferences.locale);
            //     }
            // } else if (token) {
            //     await this.authService.getUserFromToken();
            //     await this.userService.setAlumniAccount(isAlumniAccount.value === 'true');
            //
            // } else {
            //     console.log('UserGuard false');
            //     if (this.platformService.isCapacitor()) {
            //         await this.router.navigate(['/usertype']);
            //     } else {
            //         window.location.href = environment.alumniLoginUrl;
            //     }
            //     return false;
            // }
        }
        console.log(3);
        console.log('UserGuard true');
        return true;
    }

    private async getBrowserLocale() {
      const browserLocale = await Device.getLanguageCode();
      const locale = browserLocale.value;
      if (locale === 'es' || locale === 'ca') {
        return locale;
      } else {
        return 'es';
      }
    }
}
