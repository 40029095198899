import { UserCredential, getAdditionalUserInfo } from 'firebase/auth';

export class User {
    readonly ROLE_FAMILY = 'ROLE_FAMILY';
    readonly ROLE_PROFESSIONAL = 'ROLE_PROFESSIONAL';
    readonly ROLE_SCHOOL = 'ROLE_SCHOOL';
    readonly ACCOUNT_GOOGLE = 'google';
    readonly ACCOUNT_FACEBOOK = 'facebook';
    readonly ACCOUNT_APPLE = 'apple';
    readonly ACCOUNT_EMAIL = 'email';

    token: string;
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    centerName: string;
    password: string;
    role: string;
    alumnis = [];
    hasStartedTrial = false;
    isSemesterSubscription = false;
    numSubscriptors = 0;
    numMaxSubscriptors = 0;
    numCredits = 0;
    preferences: Preferences;
    accountType: string;
    isInTrial = false;
    suscription: Suscription;
    // testDisclaimer = false;
    // legalConditions = false;
    // legalNewsletters = false;
    // legalExternals = false;
    // childrenPermissions = false;
    // directoryPermissions = false;

    constructor() {
        this.preferences = new Preferences();
    }

    populate(data) {
        this.firstname = data.firstname ? data.firstname : '';
        this.lastname = data.lastname ? data.lastname : '';
        this.alumnis = [];
        this.centerName = data.center_name;
        this.hasStartedTrial = data.hasStartedTrial;
        this.isSemesterSubscription = data.isSemesterSubscription;
        this.numSubscriptors = data.numSubscriptors;
        this.numMaxSubscriptors = data.numMaxSubscriptors;
        this.numCredits = data.numCredits;
        this.role = data.role;
        this.accountType = data.accountType;
        this.isInTrial = data.isInTrial;
        this.preferences.dashboard = data.dashboardType ?  data.dashboardType : 'table';
        this.preferences.locale = data.locale ? data.locale : 'es';

        if (data.activeSubscription) {
            this.suscription = new Suscription();
            this.suscription.populate(data.activeSubscription);
        } else {
            this.suscription = null;
        }
        console.log('user populated', this);
    }

    isFamily() {
        return this.role === this.ROLE_FAMILY;
    }

    isProfessional(){
        return this.role === this.ROLE_PROFESSIONAL;
    }

    isSchool(){
        return this.role === this.ROLE_SCHOOL;
    }

    isGoogleAccount() {
        return this.accountType === this.ACCOUNT_GOOGLE;
    }

    isFacebookAccount() {
        return this.accountType === this.ACCOUNT_FACEBOOK;
    }

    isAppleAccount() {
        return this.accountType === this.ACCOUNT_APPLE;
    }

    isEmailAccount() {
        return this.accountType === this.ACCOUNT_EMAIL;
    }
}

export class Suscription {
    type: string;
    status: boolean;
    endDate: string;
    numMaxAlumnis = 2;
    numAlumnis = 0;

    populate(data) {
        this.type = data.type;
        this.status = data.status;
        this.endDate = data.endDate;
        this.numMaxAlumnis = data.numMaxAlumnis;
        this.numAlumnis = data.numAlumnis;
    }
}

export class Preferences {
    dashboard: string;
    locale: string;
}

export class UserOauth {
    uid: string;
    googleId = '';
    facebookId = '';
    appleId = '';
    email: string;
    firstName: string;
    lastName: string;

    constructor(firebaseUser: UserCredential, provider: string) {
        this.uid = firebaseUser.user.uid;
        this.email = firebaseUser.user.email;
        const additionalInfo = getAdditionalUserInfo(firebaseUser);
        this.firstName = additionalInfo.profile['given_name'] as string;
        this.lastName = additionalInfo.profile['family_name'] as string;

        switch (provider) {
            case 'google':
                this.googleId = firebaseUser.user.providerData[0].uid;
                break;
            case 'facebook':
                this.facebookId = firebaseUser.user.providerData[0].uid;
                break;
            case 'apple':
                this.appleId = firebaseUser.user.providerData[0].uid;
                break;
        }
    }
}
