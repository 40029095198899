/** Angular Modules **/
import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';

/** Ionic Modules **/
import {AlertController, Platform} from '@ionic/angular';

/** Third Party modules **/
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

/** models and config **/
import {environment} from '@environments/environment';
import { Alumni } from 'src/app/model/alumni';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private serverURL = environment.apiURL;

  constructor(private httpClient: HttpClient,
              private platform: Platform,
              private alertController: AlertController,
              private translateService: TranslateService,
              private router: Router) {
  }

  /**
   * GET App Versions
   *
   * @param platform string
   */
  public getAppVersionInStore(platform: string): Observable<string> {
    return this.httpClient.get<string>(
        `${this.serverURL}/app/version/${platform}`
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    );
  }

  userExist(email: string): Promise<any> {
    return this.httpClient.post<any>(
        `${this.serverURL}/user/exist`,
        {email}
    ).pipe(
        retry(3), // retry a failed request up to 3 times
    ).toPromise();
  }

  isCMValidUser(email: string): Promise<any> {
    return this.httpClient.post<any>(
      `${this.serverURL}/user/cm/valid`,
      {email}
    ).pipe(
      retry(3), // retry a failed request up to 3 times
    ).toPromise();
  }

  userExistWithProviders(email: string): Promise<any> {
    return this.httpClient.post<any>(
        `${this.serverURL}/user/exist/providers`,
        {email}
    ).pipe(
        retry(3), // retry a failed request up to 3 times
    ).toPromise();
  }

  login(username: string, password: string): Promise<any>  {
    return this.httpClient.post<any>(
        `${this.serverURL}/login`,
        {username, password}
    ).toPromise();
  }

  rememberPassword(email: string, dynamicLink: string): Promise<any>  {
    return this.httpClient.post<any>(
      `${this.serverURL}/app/user/remember`,
      {email, dynamicLink}
    ).toPromise();
  }

  setNewUserPassword(email: string, password: string): Promise<any>  {
    return this.httpClient.post<any>(
      `${this.serverURL}/app/user/new/password`,
      {email, password}
    ).toPromise();
  }

  setNewUser(newUser: object): Promise<any>  {
    return this.httpClient.post<any>(
      `${this.serverURL}/user`,
      newUser
    ).toPromise();
  }

  /**
   * Get User data
   *
   * @param uid: string
   */
  getUser(uid: string): Promise<any> {
    return this.httpClient.get<JSON>(
        `${this.serverURL}/v2/user/${uid}`
    ).pipe(
        retry(3), // retry a failed request up to 3 times
    ).toPromise();
  }

  updateUser(uid: string, newUser: object): Promise<any>  {
    return this.httpClient.post<any>(
        `${this.serverURL}/v2/user/${uid}`,
        newUser
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  deleteUser(): Promise<any>  {
    return this.httpClient.post<any>(
        `${this.serverURL}/v2/delete/user`, null
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * Post preferences like dashboard visualization
   *
   * @param preferences object
   */
  postUserPreferences(preferences: object) {
    return this.httpClient.post<any>(
      `${this.serverURL}/v2/preferences/user`,
      preferences
    ).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * Get USer Alumnis paginated
   *
   * @param uid: string
   * @param page: number
   * @param search: string
   */
  getUserAlumnis(uid: string, page: number, search: string): Promise<any> {
    return this.httpClient.get<JSON>(
      `${this.serverURL}/v2/user/${uid}/alumnis/${page}?search=${search}`
    ).pipe(
      retry(3), // retry a failed request up to 3 times
    ).toPromise();
  }

  /**
   * Start a user trial and returns the updated user
   *
   * @param uid string
   */
  startTrial(uid: string): Promise<any> {
    return this.httpClient.post<JSON>(
        `${this.serverURL}/v2/user/${uid}/trial`,
        {}
    ).pipe(
        retry(3), // retry a failed request up to 3 times
    ).toPromise();
  }

  /**
   * Start a user trial and returns the updated user
   *
   * @param uid string
   * @param platform string
   */
  setSubscription(uid: string, platform: string): Promise<any> {
    return this.httpClient.post<JSON>(
        `${this.serverURL}/v2/user/${uid}/subscription`,
        {
          platform
        }
    ).pipe(
        retry(3), // retry a failed request up to 3 times
    ).toPromise();
  }

  alumniExist(username: string): Promise<any> {
    return this.httpClient.post<any>(
      `${this.serverURL}/alumni/exist`,
      {username}
    ).pipe(
      retry(3), // retry a failed request up to 3 times
    ).toPromise();
  }

  alumniLogin(username: string, password: string): Promise<any>  {
    return this.httpClient.post<any>(
      `${this.serverURL}/alumni/login`,
      {username, password}
    ).toPromise();
  }

  alumniReport(id: string): Promise<any>  {
    return this.httpClient.post<any>(
      `${this.serverURL}/alumni/report`,
      {id}
    ).toPromise();
  }

  /**
   * Post assign new credits to alumni
   *
   * @param alumni Alumni
   * @param numCredits number
   */
  postAlumniAssignCredits(alumni: Alumni, numCredits: number) {
    return this.httpClient.post<any>(
        `${this.serverURL}/v2/alumni/${alumni.id}/credits`,
        {numCredits}
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * Post alumni preferences like music on/off
   *
   * @param alumni Alumni
   * @param preferences object
   */
  postAlumniPreferences(alumni: Alumni, preferences: object) {
    return this.httpClient.post<any>(
        `${this.serverURL}/alumni/${alumni.id}/preferences`,
        preferences
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  setNewAlumni(newAlumni: object): Promise<any>  {
    return this.httpClient.post<any>(
      `${this.serverURL}/v2/alumni`,
      newAlumni
    ).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  updateAlumni(aid: string, newAlumni: object): Promise<any>  {
    return this.httpClient.post<any>(
      `${this.serverURL}/v2/alumni/${aid}`,
      newAlumni
    ).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  deleteAlumni(aid: string): Promise<any>  {
    return this.httpClient.post<any>(
      `${this.serverURL}/v2/alumni/${aid}/delete`,
      {}
    ).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * Post new support Ticket
   *
   * @param data any
   */
  public postSupport(data: any) {
    return this.httpClient.post<any>(
        `${this.serverURL}/v2/support`,
        data
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * GET Alumni
   *
   * @param uid: string
   * @param aid: string
   */
  public getAlumni(uid: string, aid: string): Promise<any> {
    return this.httpClient.get<JSON>(
      `${this.serverURL}/v2/user/${uid}/alumni/${aid}`
    ).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  public postAddAlumniToSubscription(aid: string): Promise<any> {
    return this.httpClient.post<any>(
  `${this.serverURL}/alumni/${aid}/addToSubscription`,
        {}
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * GET Alumni Challenge
   *
   * @param aid: string
   */
  public getChallenge(aid: string): Observable<JSON> {
    return this.httpClient.get<JSON>(
        `${this.serverURL}/alumni/${aid}/challenge`
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    );
  }

  /**
   * GET Alumni Test
   *
   * @param aid: string
   */
  public getTest(aid: string): Observable<JSON> {
    return this.httpClient.get<JSON>(
        `${this.serverURL}/alumni/${aid}/test`
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    );
  }

  public setNewStoreItem(alumni: Alumni, storeItem: object) {
    return this.httpClient.post<any>(
        `${this.serverURL}/alumni/${alumni.id}/store`,
        storeItem
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  /**
   * Post alumni challenge metrics
   *
   * @param alumni Alumni
   * @param data any
   */
  public postChallenge(alumni: Alumni, data: any) {
    return this.httpClient.post<any>(
        `${this.serverURL}/alumni/${alumni.id}/challenge`,
        data
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  public postTest(aid: string, metrics: any) {
    const data = {
      platform: this.platform.platforms(),
      metrics
    };
    return this.httpClient.post<any>(
        `${this.serverURL}/alumni/${aid}/test`,
        data
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  public sendError(error, username, packageData, exerciseData) {
    const data = {
      platform: this.platform.is('ios') ? 'ios' : 'android',
      version: environment.appVersion,
      username,
      error,
      package: packageData,
      exercise : exerciseData
    };
    return this.httpClient.post<any>(
        `${this.serverURL}/error/notification`,
        data
    ).pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
    ).toPromise();
  }

  private async handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, `, error.error);
      if (error.status === 401 && error.message === 'Expired JWT Token') {
          await this.presentErrorAlert();
      }
    }
    return throwError({message: 'Something bad happened; please try again later.', status: error.status, error: error.error} ).toPromise();
  };

  private async presentErrorAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('ERROR.title'),
      message: this.translateService.instant('ERROR.sessionExpired'),
      buttons: [
        {
          text: this.translateService.instant('Ok'),
          handler: async () => {
            if (this.platform.is('capacitor')) {
              await this.router.navigateByUrl('/prephaser/usertype', {replaceUrl: true});
            } else {
              window.location.href = environment.alumniLoginUrl;
            }
          }
        },
      ],
      mode: 'ios',
      cssClass: 'dyuAlert',
    });
    await alert.present();
  }

}
