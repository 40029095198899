/** Angular Modules **/
import { Injectable } from '@angular/core';

/** Ionic Angular **/
import { NavController } from '@ionic/angular';

/** Capacitor Modules **/
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

/** Third Party Modules **/
import { Subject } from 'rxjs';
import * as Sentry from '@sentry/capacitor';

/** Firebase Modules **/
import {
  Auth,
  getAdditionalUserInfo,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  OAuthProvider,
  signInWithCredential,
  signInWithPopup,
  UserCredential,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import { getApp } from 'firebase/app';

/** Services **/
import { UserService } from '@services/user.service';
import { ApiService } from '@services/api.service';
import { PurchasesService } from '@services/purchases.service';
import { PlatformService } from '@services/platform.service';

/** config **/
import { environment } from '@environments/environment';


export interface DytAuthResponse {
  existingUser: boolean;
  email?: string;
  uid?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  init$ = new Subject<boolean>();
  initialized = false;

  private auth: Auth;

  constructor(private userService: UserService,
              private apiService: ApiService,
              private navCtrl: NavController,
              private purchaseService: PurchasesService,
              private platformService: PlatformService) { }

  /**
   * Init the auth service
   */
  init() {
    this.auth = this.whichAuth();
    this.initialized = true;
    this.init$.next(true);
  }

  /**
   * Returns the active auth component
   *
   * @returns Auth
   */
  whichAuth() {
    let auth;
    if (Capacitor.isNativePlatform()) {
      auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence
      });
    } else {
      auth = getAuth();
    }
    return auth;
  }

  async getToken(){
    const { value } = await Preferences.get({key: 'authToken'});
    return value;
  }

  /** Returns a User using an auth token **/
  public async getUserFromToken(token = null, alumniId: string = null) {
    if (token) {
      await Preferences.set({key: 'authToken', value: token });
    }
    const { value } = await Preferences.get({key: 'authToken'});
    this.userService.setUserWithToken(value);
    await this.userService.getUser(alumniId);
  }

  /**
   * Returns if we have an auth token in device (previously logged user)
   */
  async existToken(): Promise<boolean>{
    const { value } = await Preferences.get({key: 'authToken'});
    return value != null;
  }

  /**
   * Returns if auth user is an alumni
   */
  async isAlumniAccount(): Promise<boolean> {
    const res = await Preferences.get({key: 'isAlumniAccount'});
    return res.value === 'true';
  }

  /**
   * Remove user tokens after logout
   */
  public async removeToken(){
    await Preferences.set({key: 'isAlumniAccount', value: 'false'});
    await Preferences.remove({key: 'userActiveAlumni'});
    await Preferences.remove({key: 'authToken'});
  }

  /** GOOGLE **/

  /**
   * Launch Login with google flow and returns the user
   *
   * @returns Promise<DytAuthResponse>
   */
  async signInWithGoogle():  Promise<DytAuthResponse> {
    let userCredential: UserCredential | void;
    if (this.platformService.isCapacitor()) {
      userCredential = await this.loginWithGoogleNative();
    } else {
      userCredential = await this.loginWithGoogleWeb();
    }

    if (userCredential) {
      const additionalInfo = getAdditionalUserInfo(userCredential);
      if (additionalInfo.isNewUser) {
        const existUserInDB = await this.userService.existUserWithProviders(userCredential.user.email);
        if (!existUserInDB.exist) {
          this.userService.setOauthFields(userCredential, 'google');
          return { existingUser: false };
        } else {
          this.auth.currentUser.delete();
          // eslint-disable-next-line no-throw-literal
          throw {
            error: 'existUserInDB',
            data: existUserInDB
          };
        }
      } else {
        return {
          existingUser: true,
          email: userCredential.user.email,
          uid: userCredential.user.uid
        };
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        error: 'canceled'
      };
    }
  }

  /**
   * Launch Login with google native
   *
   * @private
   * @returns Promise<UserCredential>
   */
  private async loginWithGoogleNative(): Promise<UserCredential> {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle()
        .catch(error => {
          console.log('error', error);
        });
    // 2. Sign in on the web layer using the id token and nonce
    if (result) {
      const credential = GoogleAuthProvider.credential(result.credential?.idToken);
      const userSignedIn = await signInWithCredential(this.auth, credential);
      console.log('userSignedIn', userSignedIn);
      return userSignedIn;
    }
    return null;
  }

  /**
   * Show Login with google popup for web
   *
   * @private
   * @returns Promise<UserCredential>
   */
  private async loginWithGoogleWeb(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

  /** FACEBOOK **/

  /**
   * Launch Login with facebook flow and returns the user
   *
   * @returns Promise<User>
   */
  async signInWithFacebook(): Promise<DytAuthResponse> {
    let userCredential: UserCredential | void;
    if (this.platformService.isCapacitor()) {
      userCredential = await this.loginWithFacebookNative();
    } else {
      userCredential = await this.loginWithFacebookWeb();
    }

    if (userCredential) {
      const additionalInfo = getAdditionalUserInfo(userCredential);
      if (additionalInfo.isNewUser) {
        const existUserInDB = await this.userService.existUserWithProviders(userCredential.user.email);
        if (!existUserInDB.exist) {
          this.userService.setOauthFields(userCredential, 'facebook');
          return { existingUser: false };
        } else {
          this.auth.currentUser.delete();
          // eslint-disable-next-line no-throw-literal
          throw {
            error: 'existUserInDB',
            data: existUserInDB
          };
        }
      } else {
        return {
          existingUser: true,
          email: userCredential.user.email,
          uid: userCredential.user.uid
        };
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        error: 'canceled'
      };
    }
  }

  /**
   * Launch Login with facebook native
   *
   * @private
   * @returns Promise<UserCredential>
   */
  private async loginWithFacebookNative(): Promise<UserCredential> {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithFacebook()
        .catch(error => {
          console.log('error', error);
        });
    // 2. Sign in on the web layer using the id token and nonce
    console.log('1', result);
    if (result) {
      const credential = FacebookAuthProvider.credential(result.credential?.accessToken);
      console.log('2', credential);
      const userSignedIn = await signInWithCredential(this.auth, credential);
      console.log('3', userSignedIn);
      console.log('userSignedIn', userSignedIn);
      return userSignedIn;
    }
    return null;
  }

  /**
   * Show Login with facebook popup for web
   *
   * @private
   * @returns Promise<UserCredential>
   */
  private async loginWithFacebookWeb(): Promise<UserCredential> {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

  /** APPLE **/

  /**
   * Launch Login with apple flow and returns the user
   */
  async signInWithApple(): Promise<DytAuthResponse>{
    let userCredential: UserCredential | void;
    if (this.platformService.isCapacitor()) {
      userCredential = await this.loginWithAppleNative();
    } else {
      userCredential = await this.loginWithAppleWeb();
    }

    if (userCredential) {
      const additionalInfo = getAdditionalUserInfo(userCredential);
      if (additionalInfo.isNewUser) {
        const existUserInDB = await this.userService.existUserWithProviders(userCredential.user.email);
        if (!existUserInDB.exist) {
          this.userService.setOauthFields(userCredential, 'apple');
          return { existingUser: false };
        } else {
          this.auth.currentUser.delete();
          // eslint-disable-next-line no-throw-literal
          throw {
            error: 'existUserInDB',
            data: existUserInDB
          };
        }
      } else {
        return {
          existingUser: true,
          email: userCredential.user.email,
          uid: userCredential.user.uid
        };
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        error: 'canceled'
      };
    }
  }

  /**
   * Launch Login with apple native
   *
   * @private
   * @returns Promise<UserCredential>
   */
  private async loginWithAppleNative(): Promise<UserCredential> {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple();
    // 2. Sign in on the web layer using the id token and nonce
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });
    const userSignedIn = await signInWithCredential(this.auth, credential);
    console.log('userSignedIn', userSignedIn);
    return userSignedIn;
  }

  /**
   * Show Login with apple popup for web
   *
   * @private
   * @returns Promise<UserCredential>
   */
  private async loginWithAppleWeb(): Promise<UserCredential> {
    const provider = new OAuthProvider('apple.com');
    return signInWithPopup(this.auth, provider);
  }

  /**
   * Signin with email and password on Dytective Server via API
   *
   * @param email
   * @param password
   */
  public signInWithEmailAndPassword(email: string, password: string) {
    return this.apiService.login(email, password);
  }

  /**
   * Signin with username and password on Dytective Server via API
   *
   * @param username
   * @param password
   */
  public signInWithUsernameAndPassword(username: string, password: string) {
    return this.apiService.alumniLogin(username, password);
  }

  /**
   * Logout
   */
  async logout() {
    await this.removeToken();
    Sentry.configureScope(scope => scope.setUser(null));
    await this.purchaseService.logoutUser();
    if (this.platformService.isCapacitor()) {
      await this.navCtrl.navigateBack('/usertype', {replaceUrl: true});
    } else {
      window.location.href = environment.alumniLoginUrl;
    }
  }
}
