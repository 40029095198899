/** Angular Modules **/
import { Injectable } from '@angular/core';

/** Ionic Modules **/
import {ToastController} from '@ionic/angular';

/** Third party Modules **/
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController,
              private translate: TranslateService) {
  }

  async showSignInError(errorCode) {
    const toast = await this.toastController.create({
      position: 'top',
      mode: 'ios',
      color: 'warning',
      cssClass: 'dytToast',
      animated: true,
      keyboardClose: true,
      header: this.translate.instant(`LOGIN.ERROR.title`),
      message: this.translate.instant(`LOGIN.ERROR.${errorCode}`),
      duration: 7000,
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async showSignUpError(errorCode) {
    const toast = await this.toastController.create({
      position: 'top',
      mode: 'ios',
      color: 'warning',
      cssClass: 'dytToast',
      animated: true,
      keyboardClose: true,
      header: this.translate.instant(`LOGIN.SIGNUP.ERROR.title`),
      message: this.translate.instant(`LOGIN.SIGNUP.ERROR.${errorCode}`),
      duration: 5000,
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async showGenericConnectionError() {
    console.log('showGenericConnectionError llega');
    const toast = await this.toastController.create({
      position: 'top',
      mode: 'ios',
      color: 'danger',
      cssClass: 'dytToast',
      animated: true,
      keyboardClose: true,
      header: this.translate.instant('ERROR.title'),
      message: this.translate.instant('ERROR.general'),
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async showChangePasswordSuccess() {
    const toast = await this.toastController.create({
      position: 'top',
      mode: 'ios',
      color: 'success',
      cssClass: 'dytToast',
      animated: true,
      keyboardClose: true,
      header: this.translate.instant('LOGIN.NEWPASSWORD.SUCCESS.title'),
      message: this.translate.instant('LOGIN.NEWPASSWORD.SUCCESS.subtitle'),
      duration: 4000,
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }

  async showSuccess(header: string, message: string) {
    const toast = await this.toastController.create({
      position: 'top',
      mode: 'ios',
      color: 'success',
      cssClass: 'dytToast',
      animated: true,
      keyboardClose: true,
      header: this.translate.instant(header),
      message: this.translate.instant(message),
      duration: 4000,
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel'
        }
      ]
    });
    await toast.present();
  }
}
