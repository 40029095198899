/** Angular Modules **/
import { Component } from '@angular/core';

/** Third party modules **/
import { OrientationType, ScreenOrientation } from '@capawesome/capacitor-screen-orientation';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();


/** Services **/
import { DynamicLinksService } from '@services/dynamic-links.service';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@services/platform.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private dynamicLinksService: DynamicLinksService,
              private translate: TranslateService,
              private platformService: PlatformService) {
    this.initializeApp();
  }

  private async initializeApp() {
    if (this.platformService.isCapacitor()) {
      await ScreenOrientation.lock({type: OrientationType.PORTRAIT});
    }
    // Handle deep links
    this.dynamicLinksService.handleDeepLinkWithoutAppLaunched();

    this.translate.setDefaultLang('es');
    this.translate.use('es');

    // Set default lang
    // const browserLocale = await Device.getLanguageCode();
    // console.log('browserLocale', browserLocale.value);
    // if (browserLocale.value === 'ca') {
    //   this.translate.use('ca');
    // } else {
    //   this.translate.use('es');
    // }
  }
}
