/** Angular Modules **/
import { Injectable } from '@angular/core';

/** Ionic Modules **/
import {Platform} from '@ionic/angular';

/** Services **/
import { ApiService } from '@services/api.service';


@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(private platform: Platform,
              private apiService: ApiService) {}

  getAppVersion(): Promise<string> {
    return new Promise((resolve, reject) => {
      const subscription = this.apiService.getAppVersionInStore(this.getPlatformSO()).subscribe(
          response => {
            subscription.unsubscribe();
            resolve(response);
          },
          error => {
            subscription.unsubscribe();
            reject(error);
          }
      );
    });
  }

  getServerVersion() {
    return new Promise((resolve, reject) => {
      const subscription = this.apiService.getAppVersionInStore('server').subscribe(
          response => {
            subscription.unsubscribe();
            resolve(response);
          },
          error => {
            subscription.unsubscribe();
            reject(error);
          }
      );
    });
  }

  isWeb() {
    return  this.platform.is('desktop');
  }

  isTablet() {
    return  this.platform.is('tablet');
  }

  isCapacitor() {
    return this.platform.is('capacitor');
  }

  isAndroid() {
    return this.platform.is('android');
  }

  isIos() {
    return this.platform.is('ios');
  }

  getDeviceType() {
    if (this.platform.is('mobile')) {
      return 'smartphone';
    } else {
      return 'tablet';
    }
  }

  getPlatformSO() {
    if (this.platform.is('android')) {
      return 'android';
    } else {
      return 'ios';
    }
  }

  getPlatformStore() {
    if (this.platform.is('android')) {
      return 'Google Play';
    } else {
      return 'App Store';
    }
  }

  height() {
    return this.platform.height();
  }

  ready() {
    return this.platform.ready();
  }
}
